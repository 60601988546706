import React from "react"
import "./styles.css"
import { openHubspotChat } from "../../utils"
import { useAnalytics } from "../../hooks/useTracking"

const included = [
  "All features in professional",
  "10-1000+ websites",
  "3-50 teammates",
  "Dedicated support team",
  "SIEM integration",
  "API for integrations",
  "Emergency hack repair",
  "Penetration testings",
  "Performance testings ",
]

const CustomPlanPricing = () => {
  const analytics = useAnalytics()
  return (
    <section
      data-sal="slide-up"
      data-sal-delay="100"
      data-sal-easing="ease"
      className="custom-plan-pricing"
    >
      <div className="container">
        <div className="custom-plan-pricing__wrapper">
          <div className="custom-plan-pricing__header">
            <h4>Custom plan</h4>
            <p>
              If you need enterprise coverage for multiple websites, contact for
              a custom solution.
            </p>
            <button
              className="wt-button wt-button--white"
              onClick={() => {
                analytics.getInTouch()
                openHubspotChat()
              }}
            >
              <span>Get in touch</span>
            </button>
          </div>
          <div className="custom-plan-pricing__body">
            <h5>What's included :</h5>
            <div className="custom-plan-pricing__body-items-wrapper">
              <div className="custom-plan-pricing__body-items">
                {included.map(item => (
                  <div key={item} className="custom-plan-pricing__body-item">
                    <span>{item}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CustomPlanPricing
